
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















.picture__container {
  @include fluid(
    height,
    (
      s: 270px,
      xl: 535px,
    )
  );

  position: relative;
  display: flex;
  justify-content: center;
  padding: $spacing $spacing * 2 $spacing * 5;
  background-color: $c-black-20;
  border-radius: $spacing * 2;

  img {
    @include get-all-space;

    object-fit: contain; // TBC
    padding-bottom: 10rem;
  }

  @include mq(m) {
    padding: $spacing * 2 $spacing * 2 $spacing * 6;
  }
}
