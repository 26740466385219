
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































































































.product-single__hero {
  @include fluid(
    padding-bottom,
    (
      s: 36px,
      xl: 60px,
    )
  );
}
.product-single__picture + .product-single__keypoints {
  margin-top: -8rem;
}

// Transitions

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(40px);
}
