
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































.resume {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 8rem;

  ::v-deep {
    [class*='action--'] {
      @include mq(m) {
        background-color: $c-white;
      }
    }
  }
}

.resume__item {
  display: flex;
  align-items: flex-start;
  padding: 3rem col(1, 24);
  border-bottom: 1px solid $c-black-40;
}

.resume__item + .resume__item {
  margin-top: 2.4rem;

  @include mq(m) {
    margin-top: 2rem;
  }
}

.product__label-container {
  display: flex;

  @include mq($until: m) {
    flex-direction: column;
    padding-top: 0.4rem;
  }

  @include mq(m) {
    align-items: center;
    flex: 1;
  }
}

.product__isotope {
  @extend %tag;
  @extend %text-nodecoration;

  display: block;
  margin-right: 2rem;

  @include mq(m) {
    margin-right: 6rem;
  }
}

.product__label {
  margin-right: 10rem;
  font-size: 1.4rem;

  @include mq($until: m) {
    margin-bottom: 1rem;
  }

  @include mq(m) {
    font-size: 1.6rem;
  }
}

.product__target {
  @extend %text-nodecoration;

  display: block;
  color: $c-black-80;
  font-size: 1.4rem;

  @include mq($until: m) {
    margin-bottom: 0.8rem;
  }
}

.product__synthetizers {
  @extend %ff-alt;
  @extend %list-nostyle;

  color: $c-black-60;
  font-size: 1.3rem;

  span + span {
    &::before {
      content: '/';
      margin-left: 0.6rem;
      padding-left: 0.6rem;
    }
  }

  @include mq(m) {
    margin-left: auto;
  }
}
